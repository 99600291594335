
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { IApp, IAutoResponse } from '@/utils/types'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Watch } from 'vue-property-decorator'
import { ACTION_APP, ACTION_AUTO_RESPONSE } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'
import AutoResponseFormModal from '@/components/auto-response/AutoResponseFormModal.vue'
import { AUTO_RESPONSE } from '@/utils/constants'

@Options({
  components: { AutoResponseFormModal, CLoading },
  directives: { maska },
})
export default class AutoResponses extends Vue {
  modalVisible = false
  selectedAutoResponse: IAutoResponse = {}
  loading = false
  selectedApp: IApp = {
    auto_reply: {
      is_auto_reply: false,
    },
  }

  get appId() {
    return this.$route.params.app_id
  }

  get autoResponses(): IAutoResponse[] {
    return cloneDeep(this.$store.getters.autoResponses) || []
  }

  getResponseType(response: IAutoResponse) {
    if (response.response_type === AUTO_RESPONSE.FIX_RESPONSE) {
      return this.$t('label.auto_response.fix_response')
    } else {
      return this.$t('label.auto_response.keyword_response') + ' (' + response.response_keywords?.length + ')'
    }
  }

  getScheduling(response: IAutoResponse) {
    if (!response.is_schedule) {
      return this.$t('label.auto_response.always')
    } else if (response.schedule_type === AUTO_RESPONSE.DATE_SCHEDULE) {
      return response.from_date + ' ~ ' + response.to_date
    } else {
      return response.from_time + ' ~ ' + response.to_time + ' ⮂'
    }
  }

  @Watch('appId', { immediate: true })
  async appIdChanged() {
    if (!this.appId) {
      return
    }
    this.loading = true
    this.selectedApp = await this.$store.dispatch(ACTION_APP.LOAD_ITEM, this.appId)
    await this.$store.dispatch(ACTION_AUTO_RESPONSE.LOAD_ITEMS, {
      app_id: this.appId,
    })

    this.loading = false
  }

  onAdd() {
    this.selectedAutoResponse = {
      app_id: this.appId,
    }

    this.modalVisible = true
  }

  onCopy(data: IAutoResponse) {
    const autoResponseCopy = cloneDeep(data)
    autoResponseCopy._id = ''
    autoResponseCopy.title = autoResponseCopy.title + ' Copy'
    this.selectedAutoResponse = autoResponseCopy
    this.modalVisible = true
  }

  onEdit(data: IAutoResponse) {
    this.selectedAutoResponse = data
    this.modalVisible = true
  }

  truncateMessage(message) {
    const maxLength = 40
    if (message.length > maxLength) {
      const truncatedMessage = message.substring(0, maxLength)
      return `${truncatedMessage}<br>...`
    } else {
      return message
    }
  }

  async onDelete(autoResponse: IAutoResponse) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_AUTO_RESPONSE.DELETE, autoResponse._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  async handleToggleActive(record: IAutoResponse) {
    if (!record.in_use) {
      await this.updateIsActive(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.updateIsActive(record)
        })
        .onCancel(async () => {
          record.in_use = false
        })
    }
  }

  async updateIsActive(item: IAutoResponse) {
    const isSuccess = await this.$store.dispatch(ACTION_AUTO_RESPONSE.UPDATE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleReply(app: IApp) {
    if (!app.auto_reply?.is_auto_reply) {
      await this.updateAutoReply(app)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
          cancel: {
            flat: true,
            label: this.$t('messages.no'),
          },
          ok: {
            flat: true,
            label: this.$t('messages.yes'),
          },
          persistent: true,
        })
        .onOk(async () => {
          await this.updateAutoReply(app)
        })
        .onCancel(async () => {
          if (app.auto_reply) {
            app.auto_reply.is_auto_reply = false
          }
        })
    }
  }

  async updateAutoReply(app: IApp) {
    const isSuccess = await this.$store.dispatch(ACTION_APP.UPDATE, app)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedAutoResponse = {}
  }
}
