
import { Options, mixins } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import { IAutoResponse } from '@/utils/types'
import cloneDeep from 'lodash/cloneDeep'
import InputEmojiSelector from '@/components/common/ui/InputEmojiSelector.vue'
import { maska } from 'maska'
import { ACTION_AUTO_RESPONSE } from '@/store/actions'
import { AUTO_RESPONSE } from '@/utils/constants'

@Options({
  components: { InputEmojiSelector },
  directives: { maska },
})
export default class AutoResponseFormModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  data!: IAutoResponse

  keyword = ''

  form: IAutoResponse = {
    _id: '',
    app_id: '',
    title: '',
    response_type: AUTO_RESPONSE.FIX_RESPONSE,
    response_keywords: [],
    is_schedule: false,
    schedule_type: AUTO_RESPONSE.DATE_SCHEDULE,
    from_date: '',
    to_date: '',
    from_time: '',
    to_time: '',
    message: '',
    in_use: true,
  }

  fix_response = AUTO_RESPONSE.FIX_RESPONSE
  keyword_response = AUTO_RESPONSE.KEYWORD_RESPONSE
  date_schedule = AUTO_RESPONSE.DATE_SCHEDULE
  time_schedule = AUTO_RESPONSE.TIME_SCHEDULE

  get modalTitle() {
    return this.isNew ? this.$t('label.auto_response.add_new_message') : this.$t('label.auto_response.edit_message')
  }

  get requiredRule() {
    return {
      message: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.common.messages.message'),
      }),
      title: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.auto_response.title'),
      }),
      from: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app.from'),
      }),
      to: this.$t('validate.this_field_is_required', {
        placeholder: this.$t('label.app.to'),
      }),
    }
  }

  keywordRule() {
    let error = false
    if (this.form.response_type === this.keyword_response) {
      if (!this.form.response_keywords?.length) {
        error = true
      }
    }
    if (error) {
      return (
        false ||
        this.$t('validate.this_field_is_required', {
          placeholder: this.$t('label.auto_response.keywords'),
        })
      )
    }

    return true || ''
  }

  saveKeyword() {
    if (this.keyword) {
      if (this.form.response_keywords) {
        this.form.response_keywords.push(this.keyword)
        this.keyword = ''
      }
    }
  }

  removeKeyword(keyword) {
    if (this.form.response_keywords) {
      this.form.response_keywords = this.form.response_keywords.filter((item) => item !== keyword)
    }
  }

  onSubmit() {
    this.$refs.autoResponseRef
      .validate()
      .then(async (success: boolean) => {
        if (!success) {
          return
        }
        await this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    const { dispatch } = this.$store
    let autoResponse = null
    if (this.isNew) {
      autoResponse = await dispatch(ACTION_AUTO_RESPONSE.ADD_NEW, {
        ...this.form,
      })
    } else {
      autoResponse = await dispatch(ACTION_AUTO_RESPONSE.UPDATE, {
        ...this.form,
      })
    }
    if (autoResponse) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }
    this.visible = false
  }

  mounted() {
    this.$nextTick(() => {
      this.$refs.autoResponseRef.validate()
    })
  }

  async created() {
    this.form = { ...this.form, ...cloneDeep(this.data) }
    this.parentForm = this.form
    this.initForm = cloneDeep(this.parentForm)
  }
}
